@use '@styles/variables.scss';
// @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap');

.title {
    // color: variables.$colorBlack;
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: bold;
}

.subtitle {
    // color: variables.$colorBlack;
    font-size: 22px;
    margin-bottom: 10px;
}

.titleSvg{
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .title{
        margin-bottom: 0;
    }

    svg{
        margin-left: 10px;
    }
}

.new-line {
    white-space: pre-line;
}