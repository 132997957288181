.oauth-button{
    background-color: #005281 !important;
    color: #fff !important;
    font-weight: 500 !important;
    transition: 500ms all;
    padding: 10px !important;
    width: 100% !important;
    background: rgba(255, 255, 255, 0.9019607843);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.oauth-button__cancel{
    background-color: #919191 !important;
}

.messageBlock{
    color: #fff;
    background: #13131d;
    border-radius: 10px;
    padding: 50px 15px 30px 15px;
    position: relative;
}

.messageBlock .messageBlock__hint{
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 12px;
    color: #9b9b9b;
}

.defaultWrapper{
    min-height: 549px !important;

    .navbar .navbar__logo .navbar__logo-text{
        font-size: 25px !important;
    }
}