@use '@styles/variables.scss';

.coloredWrapper {
    background: variables.$colorNoGradient;
    background: variables.$colorGradient;    
    min-height: 100vh;
}

.defaultWrapper{
    background-color: #fff;
    min-height: 100vh;
    color: variables.$colorBlack;

    &.wrapper-DAO{
        background-image: -webkit-linear-gradient(123deg, rgb(168, 21, 27) 0%, rgb(21, 39, 62) 100%);
        color: variables.$colorWhite;
    }

    &.wrapper-ROYALTY{
        background-image: -webkit-linear-gradient(123deg, rgb(28, 112, 142) 0%, rgb(52, 7, 86) 100%);
        color: variables.$colorWhite;
    }
    
    &.wrapper-AMBASSADOR{
        background-image: -webkit-linear-gradient(123deg, rgb(28, 105, 193) 0%, rgb(48, 0, 45) 100%);
        color: variables.$colorWhite;
    }
    
    &.wrapper-METAVERSE{
        background-image: -webkit-linear-gradient(123deg, rgb(28, 105, 193) 0%, rgb(48, 0, 45) 100%);        
        color: variables.$colorWhite;
    }
}

.container {
    max-width: 1200px;
    width: calc(100% - 50px);
    margin: 0 auto;
}