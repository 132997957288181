@import '@fonts/BebasNeue-Bold/style.css';
@import '@fonts/BebasNeue-Bold/style.css';

.myWallets__wrap{
    margin-top: 50px;

    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // grid-gap: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.nftCollections{
    margin-top: 40px;
    margin-bottom: 40px;

    display: flex;
    align-items: center;

    flex-wrap: wrap;
    row-gap: 20px;

    .nftCollections__wrap{
        // background: red;
        // padding: 20px;
        // width: 100px;
        // height: 100px;
        // border-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        &:not(.nftCollections__wrap-has){
            filter: grayscale(1);
        }

        * {
            width: 102px;
            height: 102px;
        }
    }
}

.nftCollections > div:empty {
    display: none;
}

.nftCollectionsDetail{

    display: flex;
    align-items: center;    
    margin-top: 30px;

    .nftCollectionsDetail__imageWrap{

        position: relative;

        .nftCollectionsDetail__image{
            width: 300px;
        }

        .nftCollectionsDetail__tokenId{
            position: absolute;
            bottom: 30px;
            color: #5896ab;
            font-weight: bold;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 15px;
        }
    }
}


.walletCheck {
    margin: 0 0 10px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 5px;
    display: grid;
    grid-gap: 20px;
}

.walletCheck__content {
    border-radius: 24px;
    width: 100%;
    display: flex;
    align-items: center;
}

.walletCheck__name {
    background-color: #e3a078;
    height: 38px;
    max-width: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    position: relative;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Bebas Neue' !important;
    text-shadow: 0.707px 0.707px 1px rgba(0, 0, 0, 0.6);
    margin-right: 30px;
}

.walletCheck__name:after {
    content: "";
    clear: both;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #1eb428;
    border: 1px solid #d5c4a2;
}

.walletCheck__btnWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;

    .walletCheck__btnWrap-comingSoon{
        color: #fff;
    }
}

.walletCheck__btnWrap-empty {
    min-width: 143px;
}

.walletCheck__available {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ecffe9;
    height: 38px;
    border-radius: 19px;
    justify-content: space-between;
    padding: 0 16px;
}

.walletCheckAvailable__text {
    color: #2d1454;
    font-size: 16px;
    font-weight: 400;
}

.walletCheckAvailable__value {
    font-family: 'Bebas Neue';
}

.walletCheck__btnWrap .walletBttn {
    background-color: #8787cd;
}

.walletCheck__btnWrap .walletBttn .walletBttn__label {
    margin-left: 15px;
    color: #fff;
}

.aristippusNft-link{
    text-decoration: none;
}

.walletCheck__btnWrap .walletBttn .walletBttn__icon {
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.walletCheck__btnWrap .walletBttn:hover {
    background-color: #2d1454;
}

.walletBalance__value-gico:after {
    clear: both;
    content: 'GICO';
    display: inline-block;
    font-family: 'Bebas Neue';
    font-weight: bold;
    color: #be739b;
    padding-left: 5px;
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.walletCheck-gico {
    background-color: #141950;
}

.walletCheck-gico .walletCheck__name {
    background-image: -moz-linear-gradient(45deg, rgb(215, 10, 132) 0%, rgb(81, 18, 127) 100%);
    background-image: -webkit-linear-gradient(45deg, rgb(215, 10, 132) 0%, rgb(81, 18, 127) 100%);
    background-image: -ms-linear-gradient(45deg, rgb(215, 10, 132) 0%, rgb(81, 18, 127) 100%);
}

.walletCheck-gico .walletCheck__available {
    background-color: #373e83;
}

.walletCheck-gico .walletCheckAvailable__text {
    color: #fff;
}

.walletCheck-gico .walletCheckAvailable__value {
    color: #fff;
}

.walletCheck-gico .walletBalance__value-gico:after {
    color: #a95bff;
    font-family: 'Dreamscape';
    font-size: 22px;
    line-height: 100%;
}

.walletCheck-gico .walletCheck__btnWrap .walletBttn {
    border: 1px solid #8f91aa;
    background-color: #1e2355;
}

.walletCheck-gico .walletCheck__btnWrap .walletBttn:hover {
    background-color: #4b0296;
}

.walletCheck-gico .walletBttn-receive .walletBttn__label {
    color: #fae10f;
    font-size: clamp(14px, 1.4vw, 14px);
    letter-spacing: 1px;
}

.walletBttn-receive {
    min-width: 170px;
}

.walletCheck-gico .walletCheck__btnWrap .walletBttn .walletBttn__icon {
    background-color: #141950;
}

.walletCheck-gico .walletBttn__icon path {
    fill: #fff;
}

.walletCheck {
    margin: 0 0 15px;
}

.cursor-default {
    cursor: default;
}


.nftBlock__wrapper {
    display: grid;
    grid-gap: 20px;
}

.nftBlock__wrapper .nftBlock {
    margin: 0 !important;
}

.nftBlock__wrapper .walletCheck {
    margin: 0 !important;
}

.walletCheck-nft {
    background-color: #f4ded0;
    /* border: 1px solid #be739b; */
}

.walletCheck-nft .walletCheck__name {
    background-color: #b71f25;
}

.walletCheck-nft .walletCheck__name:after {
    background-color: #fcff00;
}

.walletCheck__amountNft {
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-right: 6px;
    background-color: #00a908;
    height: 38px;
    border-radius: 19px;
    position: relative;
}

.walletCheck__amountNft:after {
    content: "";
    clear: both;
    position: absolute;
    bottom: 12px;
    left: 50%;
    width: calc(100% - 20px);
    height: 1px;
    border-top: 1px dashed #fff;
    transform: translate(-50%, 0);
}

.walletCheckAmountNft__label {
    color: #ffffff;
    font-size: 16px;
    background-color: #00a908;
    padding-right: 5px;
    position: relative;
    z-index: 1;
}

.walletCheckAmountNft__value {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    background-color: #00a908;
    padding-left: 5px;
    position: relative;
    z-index: 1;
}

.walletCheckAmountNft__value-span {
    color: #ffffff;
    font-size: 12px;
    font-family: 'Palatino Linotype';
}

.walletCheck-nft .walletCheck__available {
    background-color: #fff;
}

.walletCheck__availableNft {}

.walletCheckAvailableNft__row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.walletCheckAvailableNftRow__value {
    font-size: 18px;
    font-family: 'Bebas Neue' !important;
    font-weight: bold;
    color: #2d1454;
    line-height: 90%;
}

.walletCheckAvailableNftRow__name {
    width: 50px;
    text-align: right;
    margin-left: 5px;
    line-height: 90%;
    position: relative;
}

.walletCheckAvailableNftRow__name:after {
    content: "";
    clear: both;
    position: absolute;
    width: 100%;
    height: 1px;
    border-top: 1px dashed #949494;
    bottom: 2px;
    left: 0;
}

.walletCheckAvailableNftRow__name-gos {
    color: #be739b;
    font-size: 18px;
    font-family: 'Bebas Neue' !important;
    font-weight: bold;
}

.walletCheckAvailableNftRow__name-bonus {
    color: #8787cd;
    font-size: 18px;
    font-family: 'Bebas Neue' !important;
    font-weight: bold;
}

.walletCheckAvailableNftRow__name span {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-left: 2px;
    font-family: 'Bebas Neue' !important;
}

.converter__main {
    background-color: #212e48; 
    height: 100%; 
    margin-top: 40px; 
    border-radius: 6px; 
    padding: 30px 40px;

    .converter__wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px 50px;
        position: relative;
    }

    .address-card__title {
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        position: relative;
        color: #fff;
        font-size: clamp(12px, 1vw, 18px);
        font-weight: bold;
        text-shadow: 0.707px 0.707px 1px rgb(0 0 0 / 60%);
        background-color: rgb(19 19 29 / 40%);
    }

    .converter__balance {
        margin-top: 10px;
        font-size: clamp(14px, 1.1vw, 20px);
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: rgb(255 255 255 / 10%);
        border-radius: 6px;
        font-weight: 500;
    }

    .btn__wrap {
        display: flex;
        align-items: center;
    }

    .convert__btn {
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        position: relative;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        text-shadow: 0.707px 0.707px 1px rgb(0 0 0 / 60%);
        background-color: #005281;
        border: none;
        cursor: pointer;
        transition: 500ms all;
    }
    .convert__btn:hover {
        background-color: #13131d;
    }

    .converter__svg {
        height: 50px;
        width: 50px;
        color: #fff;
        position: absolute;
        top: 39%;
        right: 47.5%;
        left: 47.5%;
    }
}