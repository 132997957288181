@use '@styles/variables.scss';

.address-card__wrap{
    display: flex;
    flex-direction: column;
    margin: 5px;
    // flex: 1;
    flex: 1 1 30%;

    .address-card{

        position: relative;

        background: variables.$colorNoGradient;
        background: variables.$colorGradient;

        -webkit-box-shadow: 0px 0px 11px 1px rgba(0,0,0,0.10); 
        box-shadow: 0px 0px 11px 1px rgba(0,0,0,0.10);
        padding: 30px 40px;
        border-radius: 10px;
        z-index: 1;

        .address-card__title{
            font-size: 28px;
            font-weight: bold;
            // color: #000;

            background-color: #e3a078;
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 24px;
            position: relative;
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            font-family: "Bebas Neue";
            text-shadow: 0.707px 0.707px 1px rgb(0 0 0 / 60%);
            margin-bottom: 10px;
        }

        .address-card__balance{
            background: #ffffff36;
            margin-top: 10px;
            font-size: 20px;
            font-weight: bold;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
        }

        &.address-card__GOS{
            background: #f7d6b6;
            // border: 1px solid #ffa000;
        }
        
        &.address-card__GICO{
            background: rgb(20 25 80);
            // border: 1px solid #ffa000;
            color: #fff !important;

            .address-card__title{
                background-image: -webkit-linear-gradient(45deg, rgb(215, 10, 132) 0%, rgb(81, 18, 127) 100%);
            }
        }
        
        &.address-card__DAO{
            background: #1ba600;
            // border: 1px solid #008000;
            color: #fff !important;

            .address-card__title{
                background-image: -webkit-linear-gradient(45deg, rgb(128, 0, 0) 0%, rgb(255, 0, 0) 100%);
            }
        }
        
        &.address-card__DAOA{
            background: #ffe670;
            // border: 1px solid #e5c04e;
            position: relative;

            .daoa__image{
                position: absolute;
                z-index: 10;
                bottom: -100px;
            }

            .address-card__title{
                background-image: -webkit-linear-gradient(45deg, rgb(255, 0, 0) 0%, rgb(128, 0, 0) 100%);
            }
        }

        &.address-card__video{
            overflow: hidden;

            .address-card__videoWrap{

                position: absolute;
                background: transparent;
                bottom: 0;
                right: 0;
                border-bottom-right-radius: 12px;
                overflow: hidden;

                .address-card__videoElement{            
                    mix-blend-mode: screen;
                    width: 190px;
                    height: 190px;
                    
                }
            }
        }

        .address-card__buttons{
            display: flex;
            align-items: center;
            justify-content: center;

            .address-card__buttonWrap{

                .address-card__button{
                    background: #ffffffe6;
                    border: none;
                    padding: 9px 30px;
                    border-radius: 5px;
                    color: #ff6f00;
                    cursor: pointer;
                }

                &:not(:last-child) {
                    margin-right: 10px;
                }
                
            }
        }

        
        .address-card__mt{
            margin-top: 20px;
        }

        .address-card__flex{
            display: flex;
            align-items: center;
            justify-content: space-between;        
        }

        .address-card__copy{
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .address-card__latestTitle{
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 15px;
            color: #000;
        }

        .address-card__transNotFound{
            margin-top: 20px;
            font-size: 13px;
            text-align: left;
        }

        

    }
}

.transactions__list{
    
    background: variables.$colorNoGradient;
    background: variables.$colorGradient;
    padding: 20px;
    border-radius: 10px;

    .transactions__list-row{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-top: 10px;

        .transactions__list-from,
        .transactions__list-to{
            font-size: 14px;
        }

        .transactions__list-arrow{
            margin: 0 10px;
        }

        .transactions__list-amount{
            margin-left: 20px;
        }

    }
}